import React from 'react'
import AboutSection from '../components/AboutSection'

const AboutPage = () => {
    return (
        <div>
            <AboutSection />
        </div>
    )
}

export default AboutPage
